<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
Update Instructor
       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addInstructorvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Instructor Name"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Course Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addInstructor.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Teaching Field Name"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Teaching Field Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addInstructor.field"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">Tab image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row">
                <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="`https://api.lmitac.com/${addInstructor.image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewEl.src = Tabimage
                    addInstructor.image=''
                    ">
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1"> </b-card-text>
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="addInstructor.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_header_image"
                        v-model="addInstructor.alt_tab_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive, computed } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";
import Vue from "vue";

export default {
  setup(props) {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);
    const Tabimage = ref("media/svg/files/blank-image.svg");
    const { route } = useRouter();
    const id = route.value.params.id;
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)
    console.log(id);
    store.dispatch("instructor/Getinstructor", { id }).then((response) => {
      store.commit("instructor/GET_INSTRUCTOR", response?.data.data);
    });
    const categoriesList = ref([]);
    store.dispatch("categories/AllCategory").then((response) => {
      console.log("response", response.data.data);
      categoriesList.value = response.data.data;
    });

    const instructorForm = computed(() => store.state.instructor.instructors);
    const addInstructor = reactive(instructorForm);
    console.log("CourseForm", instructorForm);
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const addInstructorvalidate = ref();

    const save = () => {
      const formData = new FormData();

      console.log(addInstructor);
      formData.append("field", addInstructor.value.field);
      formData.append("name", addInstructor.value.name);
      formData.append("image", addInstructor.value.image);
      formData.append("_method", "put");

      //  addInstructorvalidate.value.validate().then(success => {
      //         if (success) {
      store
        .dispatch("instructor/Updateinstructor", { id, formData })
        .then((response) => {
          Vue.swal({
            icon: "success",
            title: "Updated!",

            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        });
      // }})
    };
    return {
      refInputEl,
      refPreviewEl,
      Tabimage,
      addInstructor,
      inputImageRenderer,
      addInstructorvalidate,
      id,
      instructorForm,

      save,
      required,
      email,
      categoriesList,
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
